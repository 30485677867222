import useQuery from '@engined/client/hooks/useQuery.js';
import PagePaper from '@asaprint/asap/components/PagePaper.js';
import { TimeSpentStats_Load } from '@asaprint/asap/components/TimeSpentStats.graphql';
import { DAY_VIEW_ROUTE } from '@asaprint/asap/routes.js';
import { TimeSpentStats_LoadQuery, TimeSpentStats_LoadQueryVariables } from '@asaprint/asap/schema.client.types.js';
import { Role } from '@asaprint/common/access.js';
import FormatDate from '@engined/client/components/FormatDate.js';
import Loading from '@engined/client/components/Loading.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { url } from '@engined/core/services/routes.js';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, ButtonGroup, IconButton, SxProps } from '@mui/material';
import { addDays, endOfDay, format, startOfDay, subDays } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

type TimeEntriesStatsPerDay = {
  date: Date;
  dateString: string;
} & Record<string, number>;

function formatDuration(seconds: number): string {
  const hours = seconds / 60 / 60;
  const numberFormat = new Intl.NumberFormat('sk-SK', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  return `${numberFormat.format(hours)} hod.`;
}

interface Props {
  sx?: SxProps;
}

const TimeSpentStats: React.FunctionComponent<Props> = ({ sx }) => {
  const navigate = useNavigate();
  const [timeEntriesEnd, setTimeEntriesEnd] = useState<Date>(endOfDay(new Date()));
  const { loading, data, error } = useQuery<TimeSpentStats_LoadQuery, TimeSpentStats_LoadQueryVariables>(
    TimeSpentStats_Load,
    {
      variables: {
        from: subDays(startOfDay(timeEntriesEnd), 6).toISOString(),
        to: timeEntriesEnd.toISOString(),
        roles: [Role.GraphicDesigner, Role.Operator, Role.OperatorAndGraphicDesigner, Role.PrintOperator],
      },
    },
  );

  const timeEntriesSumsPerDay = data?.timeEntriesSumsPerDay;
  const timeEntriesStats = useMemo<TimeEntriesStatsPerDay[]>(() => {
    if (!timeEntriesSumsPerDay) {
      return [];
    }

    const groupByDay = timeEntriesSumsPerDay.days.reduce((acc, cur) => {
      acc[cur.date] = acc[cur.date] ?? {};
      acc[cur.date][cur.user.fullName] = cur.duration;
      return acc;
    }, {});

    return Object.keys(groupByDay)
      .map((date) => {
        const day = groupByDay[date];
        return {
          date: new Date(date),
          dateString: format(new Date(date), 'dd.MM.yyyy'),
          // ...zeros,
          ...day,
        };
      })
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  }, [timeEntriesSumsPerDay]);

  const onPrevTimeEntriesClick = useEventCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setTimeEntriesEnd((s) => subDays(s, 7));
  });

  const onNextTimeEntriesClick = useEventCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setTimeEntriesEnd((s) => {
      const next = addDays(s, 7);
      return next < new Date() ? next : endOfDay(new Date());
    });
  });

  const onBarClick = useEventCallback((data: TimeEntriesStatsPerDay, index, u) => {
    navigate(url(DAY_VIEW_ROUTE, {}, { date: data.date.toISOString().slice(0, 10), user: u.id }));
  });

  return (
    <PagePaper
      sx={sx}
      title={
        <Box display="flex" justifyContent="space-between">
          <Box>Nalogovaný čas</Box>
          <Box display="flex" alignItems="center" fontWeight="400">
            <Box sx={{ mr: 2, whiteSpace: 'nowrap' }}>
              <FormatDate date={subDays(startOfDay(timeEntriesEnd), 6)} format="dd.MM.yyyy" /> -{' '}
              <FormatDate date={timeEntriesEnd} format="dd.MM.yyyy" />
            </Box>
            <ButtonGroup>
              <IconButton size="small" onClick={onPrevTimeEntriesClick}>
                <ChevronLeft />
              </IconButton>
              <IconButton size="small" onClick={onNextTimeEntriesClick}>
                <ChevronRight />
              </IconButton>
            </ButtonGroup>
          </Box>
        </Box>
      }
    >
      <Box sx={{ height: '400px' }}>
        {error || (loading && !data) ? (
          <Loading error={error} />
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={timeEntriesStats}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="dateString" />
              <YAxis tickFormatter={formatDuration} padding={{ top: 15 }} />
              <Tooltip formatter={(value, name, props) => [formatDuration(value as number), name]} />
              {timeEntriesSumsPerDay.users.map((u, i) => (
                <Bar
                  key={u.id}
                  dataKey={u.fullName}
                  fill={`#${u.color ?? '000000'}`}
                  onClick={(data, index) => onBarClick(data, index, u)}
                  cursor="pointer"
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        )}
      </Box>
    </PagePaper>
  );
};

TimeSpentStats.displayName = 'TimeSpentStats';

export default React.memo<Props>(TimeSpentStats);
