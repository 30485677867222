import { useMutation, useSubscription } from '@apollo/client/index.js';
import KanbanBoard from '@asaprint/asap/components/KanbanBoard.js';
import PageHeader from '@asaprint/asap/components/PageHeader.js';
import PagePaper from '@asaprint/asap/components/PagePaper.js';
import TasksFilter, {
  Filter,
  filterTasks,
  initialFilter,
  shouldFilter,
} from '@asaprint/asap/components/TasksFilter.js';
import TimeSpentStats from '@asaprint/asap/components/TimeSpentStats.js';
import { useAuth } from '@asaprint/asap/contexts/AuthContext.js';
import authenticated from '@asaprint/asap/decorators/authenticated.js';
import { onMoveHelper } from '@asaprint/asap/helpers/planning.js';
import useUserSettings from '@asaprint/asap/hooks/useUserSettings.js';
import { ORDERS_RECEIVED_ROUTE } from '@asaprint/asap/routes.js';
import {
  Dashboard_Load,
  Dashboard_TaskCreated,
  Dashboard_TaskMove,
  Dashboard_TaskUpdated,
} from '@asaprint/asap/routes/__authenticated/index.graphql';
import {
  Dashboard_LoadQuery,
  Dashboard_LoadQueryVariables,
  Dashboard_TaskCreatedSubscription,
  Dashboard_TaskCreatedSubscriptionVariables,
  Dashboard_TaskFragment,
  Dashboard_TaskMoveMutation,
  Dashboard_TaskMoveMutationVariables,
  Dashboard_TaskUpdatedSubscription,
  Dashboard_TaskUpdatedSubscriptionVariables,
  Dashboard_UserFragment,
} from '@asaprint/asap/schema.client.types.js';
import { Role } from '@asaprint/common/access.js';
import { ORDER_RECEIVED_PHASE_NAMES } from '@asaprint/common/constants/OrderReceived.js';
import { isScheduledToday, isScheduledTomorrow } from '@asaprint/common/helpers/OrderReceived.js';
import { displayName } from '@asaprint/common/helpers/User.js';
import Link from '@engined/client/components/Link.js';
import Loading from '@engined/client/components/Loading.js';
import { displayError } from '@engined/client/helpers/errors.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import useQuery from '@engined/client/hooks/useQuery.js';
import { LoaderFunctionArgs, MetaFunctionArgs } from '@engined/core/interfaces.js';
import { getLogger } from '@engined/core/services/logger.js';
import { url } from '@engined/core/services/routes.js';
import { Box, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';

const logger = getLogger('@intranet/routes/auth/login');

type Task = Dashboard_TaskFragment;

interface OrdersByPhase {
  phase: string;
  count: number;
  users: {
    name: string;
    count: number;
  }[];
}

interface OrdersByUser {
  name: string;
  count: number;
}

interface Props {}

const Page: React.FunctionComponent<Props> = () => {
  const { requestUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { loading, data, error, refetch } = useQuery<Dashboard_LoadQuery, Dashboard_LoadQueryVariables>(
    Dashboard_Load,
    {
      variables: {
        id: requestUser.id,
      },
    },
  );

  const [taskMoveExecute] = useMutation<Dashboard_TaskMoveMutation, Dashboard_TaskMoveMutationVariables>(
    Dashboard_TaskMove,
  );

  const [customFilter] = useUserSettings<Filter>('Dashboard__customFilter', initialFilter);

  const user = data?.user;
  const tasks = user?.tasks;

  useSubscription<Dashboard_TaskUpdatedSubscription, Dashboard_TaskUpdatedSubscriptionVariables>(
    Dashboard_TaskUpdated,
    {
      skip: !user,
      async onData(opts) {
        try {
          if (user.id === opts.data.data.taskUpdated.userId) {
            await refetch();
          }
        } catch (err) {
          logger.error(err);
        }
      },
    },
  );

  useSubscription<Dashboard_TaskCreatedSubscription, Dashboard_TaskCreatedSubscriptionVariables>(
    Dashboard_TaskCreated,
    {
      skip: !user,
      async onData(opts) {
        try {
          if (user.id === opts.data.data.taskCreated.userId) {
            await refetch();
          }
        } catch (err) {
          logger.error(err);
        }
      },
    },
  );

  const todayTasks = useMemo<number>(
    () => tasks?.filter((t) => isScheduledToday(t.orderReceived || t.product.orderReceived)).length || 0,
    [tasks],
  );
  const tomorrowTasks = useMemo<number>(
    () => tasks?.filter((t) => isScheduledTomorrow(t.orderReceived || t.product.orderReceived)).length || 0,
    [tasks],
  );

  const hasGraphicDesignRole = isGraphicDesign(requestUser.role as Role);
  const hasProductionRole = isProduction(requestUser.role as Role);

  const users = useMemo<Dashboard_UserFragment[]>(() => {
    if (!user) {
      return [];
    }

    if (!shouldFilter(customFilter)) {
      return [user];
    }

    return [
      {
        ...user,
        tasks: filterTasks(user.tasks, customFilter),
      },
    ];
  }, [customFilter, user]);

  const ordersReceivedCountsByPhase = data?.ordersReceivedCountsByPhase;
  const ordersByPhase = useMemo<OrdersByPhase[]>(() => {
    const users = ordersReceivedCountsByPhase?.users;
    return (
      ordersReceivedCountsByPhase?.phases?.map((stats) => {
        const countsPerUser = stats.assignedUsers.reduce<{ [key: string]: number }>((acc, data) => {
          acc[data.user.id] = data.count;
          return acc;
        }, {});

        return {
          phase: stats.phase,
          count: stats.count,
          users:
            users?.map((u) => ({
              name: displayName(u),
              count: countsPerUser?.[u.id] ?? 0,
            })) || [],
        };
      }) ?? []
    );
  }, [ordersReceivedCountsByPhase]);
  const totalOrdersByUser = useMemo<OrdersByUser[]>(() => {
    return ordersReceivedCountsByPhase?.users.map((user, i) => ({
      name: displayName(user),
      count: ordersByPhase.reduce((acc, cur) => acc + cur.users[i].count, 0),
    }));
  }, [ordersReceivedCountsByPhase, ordersByPhase]);

  const onTaskMove = useEventCallback(async (task: Task, userId: string, newPosition: number) => {
    const res = onMoveHelper(task, userId, newPosition, users);
    if (!res) {
      return;
    }

    const { position, tasks } = res;
    try {
      await taskMoveExecute({
        variables: {
          id: task.id,
          updatedAt: task.updatedAt,
          userId,
          position,
        },
        update: (cache, { data: { taskMove } }) => {
          const cached = cache.readQuery<Dashboard_LoadQuery, Dashboard_LoadQueryVariables>({
            query: Dashboard_Load,
            variables: {
              id: requestUser.id,
            },
          });

          cache.writeQuery<Dashboard_LoadQuery, Dashboard_LoadQueryVariables>({
            query: Dashboard_Load,
            variables: {
              id: requestUser.id,
            },
            data: {
              ...cached,
              user: {
                ...cached.user,
                tasks: [
                  taskMove.find((t) => t.id === task.id), // Add new task to user
                  ...cached.user.tasks.filter((t) => t.id !== task.id), // Filter current task
                ].sort((a, b) => a.position - b.position), // Sort
              },
            },
          });
        },
        optimisticResponse: {
          __typename: 'Mutation',
          taskMove: tasks,
        },
      });
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  });

  return (
    <>
      <PageHeader
        title="Dashboard"
        actions={
          <TasksFilter
            todayTasksCount={todayTasks}
            tomorrowTasksCount={tomorrowTasks}
            userSettingsKey="Dashboard__customFilter"
            disableGraphicDesign={!hasGraphicDesignRole && !hasProductionRole}
            disableDataCheck={!hasGraphicDesignRole && !hasProductionRole}
            disableDesignApprove={!hasGraphicDesignRole && !hasProductionRole}
            disableBigFormatPrint={!hasProductionRole}
            disableShappedPrePlotter={!hasProductionRole}
            disableSmallFormatPrint={!hasProductionRole}
          />
        }
      />

      <Box>
        {error || (loading && !data) ? (
          <Loading error={error} />
        ) : (
          <Box display="flex">
            <Box flexBasis={375} mr={2}>
              <KanbanBoard
                users={users}
                deadlineField={hasGraphicDesignRole && !hasProductionRole ? 'approvedAt' : undefined}
                onTaskMove={onTaskMove}
                startedField={
                  hasGraphicDesignRole && !hasProductionRole ? 'graphicDesignStartedAt' : 'productionStartedAt'
                }
                sx={{ height: 'auto' }}
              />
            </Box>
            <Box flexGrow={1}>
              {ordersByPhase && (
                <PagePaper title="Prehľad otvorených objednávok">
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Fáza</TableCell>
                          {ordersReceivedCountsByPhase?.users.map((u) => (
                            <TableCell key={u.id}>{displayName(u)}</TableCell>
                          ))}
                          <TableCell>Celkom</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {ordersByPhase.map((stats) => (
                          <TableRow key={stats.phase}>
                            <TableCell>{ORDER_RECEIVED_PHASE_NAMES[stats.phase]}</TableCell>
                            {stats.users.map(({ name, count }) => (
                              <TableCell key={name}>
                                <Link
                                  to={url(
                                    ORDERS_RECEIVED_ROUTE,
                                    {},
                                    { 'rtf-phase': stats.phase, 'rtf-assignedTo': name },
                                  )}
                                >
                                  {count}
                                </Link>
                              </TableCell>
                            ))}
                            <TableCell>
                              <Link to={url(ORDERS_RECEIVED_ROUTE, {}, { 'rtf-phase': stats.phase })}>
                                {stats.count}
                              </Link>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TableCell>Celkom</TableCell>
                          {totalOrdersByUser.map(({ name, count }) => (
                            <TableCell key={name}>
                              <Link to={url(ORDERS_RECEIVED_ROUTE, {}, { 'rtf-assignedTo': name })}>{count}</Link>
                            </TableCell>
                          ))}
                          <TableCell>
                            <Link to={ORDERS_RECEIVED_ROUTE}>
                              {ordersByPhase.reduce((acc, cur) => acc + cur.count, 0)}
                            </Link>
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </PagePaper>
              )}
              <TimeSpentStats sx={{ mt: 2 }} />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

Page.displayName = 'LoginPage';

export default authenticated()(Page);

export const loader = async ({ params, request, context: { req, apollo } }: LoaderFunctionArgs) => {
  if (ENV.SERVER) {
    const result = await apollo.query({
      query: Dashboard_Load,
      variables: {
        id: req.user.id,
      },
    });
  }
  return null;
};

export const handle = {
  meta: ({ locale: { t }, meta }: MetaFunctionArgs) => ({
    title: `Dashboard | ${meta.title}`,
  }),
  breadcrumbs: [
    {
      text: 'Dashboard',
    },
  ],
};

function isGraphicDesign(role: Role): boolean {
  return [Role.GraphicDesigner].includes(role);
}

function isProduction(role: Role): boolean {
  return [
    Role.PrintOperator,
    Role.Operator,
    Role.AccountManagerAndOperator,
    Role.OperatorAndGraphicDesigner,
    Role.Expediter,
    Role.Admin,
  ].includes(role);
}
